import { React, useState } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Layout } from "antd"
import "bootstrap/dist/css/bootstrap.min.css"
import "./winter.css"
import LoginPage from "./components/LoginPage"
import ChatbotDemo from "./components/ChatbotDemo"
import DashboardContext from "./DashboardContext"
import { StytchProvider } from "@stytch/react";
import { StytchUIClient } from "@stytch/vanilla-js";
import DashboardHome from "./components/DashboardHome";
import { useStytchUser } from "@stytch/react";
import InstallChatAppPage from "./components/InstallChatAppPage";
import { GoogleOAuthProvider } from '@react-oauth/google';
import AutoDocumentPage from "./components/AutoDocumentPage";
import SlackDocumentInstall from "./components/SlackDocumentInstall";


// We initialize the Stytch client using our project's public token which can be found in the Stytch dashboard
const stytch = new StytchUIClient(process.env.REACT_APP_STYTCH_PUBLIC_TOKEN);

export default function App() {
    const [userEmail, setUserEmail] = useState(null)
    // The useStytchUser hook will return the existing Stytch User object if one exists
    const { user } = useStytchUser();



    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <BrowserRouter>
                <DashboardContext.Provider value={{ userEmail: userEmail, setUserEmail: setUserEmail }}>
                    <Layout className="page-layout" >
                        <Routes>
                            {user ?<Route path="/" element={<DashboardHome />} /> :  <Route path="/" element={<LoginPage />} />}
                            <Route path="/install" element={<InstallChatAppPage />} />
                            <Route path="/auto-doc" element={<AutoDocumentPage />} />
                            <Route path="/slack-document-install" element={<SlackDocumentInstall />} />
                        </Routes>
                    </Layout>
                </DashboardContext.Provider>
            </BrowserRouter>
        </GoogleOAuthProvider>
    )
}
