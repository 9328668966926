import React from "react"
import { render } from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { StytchProvider } from "@stytch/react";
import { StytchUIClient } from "@stytch/vanilla-js";
import TokenAuthenticator from "./components/TokenAuthenticator";
import 'antd/dist/antd.css';
import { Layout } from "antd";

const rootElement = document.getElementById("root")
const stytchOptions = {
    cookieOptions: {
        availableToSubdomains: true,
    }
}
// We initialize the Stytch client using our project's public token which can be found in the Stytch dashboard
const stytch = new StytchUIClient(process.env.REACT_APP_STYTCH_PUBLIC_TOKEN, stytchOptions);

render(
    <Layout>
        <StytchProvider stytch={stytch}>
            <TokenAuthenticator>
                <App  className="App"/>
            </TokenAuthenticator>
        </StytchProvider>
    </Layout>, rootElement)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
