import React from "react";
import { StytchLogin } from "@stytch/react";
import { OAuthProviders, OneTapPositions, Products } from "@stytch/vanilla-js";

/*
Login configures and renders the StytchLogin component which is a prebuilt UI component for auth powered by Stytch

This component accepts style, config, and callbacks props. To learn more about possible options review the documentation at
https://stytch.com/docs/sdks/javascript-sdk#ui-configs
*/
const Login = () => {
  const styles = {
    container: {
      width: "100%",
      align: "center",
      justify: "center",
    },
    buttons: {
      primary: {
        backgroundColor: "#4A37BE",
        borderColor: "#4A37BE",
      },
    },
  };
  const config = {
    products: [Products.emailMagicLinks, Products.oauth],
    emailMagicLinksOptions: {
      loginRedirectURL: process.env.REACT_APP_ENV == "production" ? "https://dashboard.spryngtime.com/":"http://localhost:3000",
      loginExpirationMinutes: 10080,
      signupRedirectURL:process.env.REACT_APP_ENV == "production" ? "https://dashboard.spryngtime.com/":"http://localhost:3000",
      signupExpirationMinutes: 10080,
    },
    oauthOptions: {
      providers: [{ type: OAuthProviders.Google, one_tap: true, position: OneTapPositions.embedded }],
      loginRedirectURL: process.env.REACT_APP_ENV == "production" ? "https://dashboard.spryngtime.com/":"http://localhost:3000",
      loginExpirationMinutes: 10080,
      signupRedirectURL: process.env.REACT_APP_ENV == "production" ? "https://dashboard.spryngtime.com/":"http://localhost:3000",
      signupExpirationMinutes: 10080,
    },
  };

  return <div className="container"><StytchLogin config={config} styles={styles} /></div>;
};

export default Login;
