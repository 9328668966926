import "react-chat-elements/dist/main.css"
import React from "react"
import { useEffect, useState, useRef } from "react"
import { MessageBox, MessageList } from "react-chat-elements"
import { Input } from "antd"
import AutoLinkText from "react-autolink-text2"

export default function Chat({ docName, setNewSentMessages, email, isMobile }) {
    const [inputValue, setInputValue] = useState("")
    const [messages, setMessages] = useState([
        <MessageBox
            position={"left"}
            type={"text"}
            title={"SupportBot"}
            text={`Hey! I'm a Support Bot. How can I help you?`}
        />,
    ])
    const [error, setError] = useState("")
    const [displayDocName, setDisplayDocName] = useState("")
    const { Search } = Input
    const [loading, setLoading] = useState(false)
    

    function sendBotMessage(message) {
        setMessages([
            ...messages,
            <MessageBox
                position={"left"}
                type={"text"}
                title={"SupportBot"}
                text={<AutoLinkText text={message} />}
            />,
        ])
    }

    useEffect(() => {
        if (messages.length > 0) {
            // Query the bot if the last message is from the user
            let text = messages[messages.length - 1].props.text
            let title = messages[messages.length - 1].props.title
            if (title === "You") {
                setError("")
                sendBotMessage("Thinking...")
                setLoading(true)
                fetch(process.env.REACT_APP_API_BASE_URL + "/query", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        domain: docName,
                        query: text,
                        email: email,
                    }),
                })
                    .then((response) => {
                        response.json().then((data) => {
                            // Check if the bot has an answer
                            if (data.answer) {
                                sendBotMessage(data.answer)
                            } else {
                                // Error
                                setError("Sorry, something went wrong, please try again later")
                            }
                            // Set new messages left
                            if(data.sent_messages){
                                setNewSentMessages(data.sent_messages)
                            }
                            setLoading(false)
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                        setError("Sorry, something went wrong, please try again later")
                        setLoading(false)
                    })
            }
            // Scroll to the bottom of the chat
            let chat = document.getElementById("chatbox")
            if (chat) {
                chat.scrollTop = chat.scrollHeight
            }
        }
    }, [messages])

    useEffect(() => {
        if (docName) {
            // Update docname to get rid of _email in the latter part
            let name = docName.split("_")[0]
            setDisplayDocName(name)
            // Update the initial bot message to include the doc name
            setMessages([
                <MessageBox
                    position={"left"}
                    type={"text"}
                    title={"SupportBot"}
                    text={`Hey! I'm a Support Bot that has ingested docs from ${displayDocName}. How can I help you?`}
                />,
            ])
        }
    }, [docName, displayDocName])

    function sendMessage() {
        setMessages([
            ...messages,
            <MessageBox position={"right"} type={"text"} title={"You"} text={inputValue} />,
        ])
        // Clear input
        setInputValue("")
    }

    useEffect(() => {
        if (docName) {
            // Auto focus on the input
            let input = document.getElementById("questionInput")
            input.focus()
        }
    }, [docName])

    return (
        <div style={{ border: "1px solid #e5e5e5", borderRadius: 10 }}>
            {error !== "" && (
                <p style={{ color: "red", fontSize: 16, textAlign: "center", marginTop: 5 }}>{error}</p>
            )}
            <div style={{ width: isMobile ? "90vw" : "55vw", height: "40vh", overflowY: "scroll", marginTop: 20 }} id="chatbox">
                {messages.map((message, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: message.props.position === "left" ? "flex-start" : "flex-end",
                            }}
                        >
                            {message}
                        </div>
                    )
                })}
            </div>
            <div
                style={{
                    width: isMobile ? "80vw" : "50vw",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "30px",
                    marginBottom: "30px",
                }}
            >
                <Search
                    placeholder="Type your questions here"
                    enterButton="Send"
                    size="large"
                    loading={loading}
                    disabled={loading}
                    onSearch={() => {
                        sendMessage()
                    }}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value)
                    }}
                    id="questionInput"
                />
            </div>
        </div>
    )
}
