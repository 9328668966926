import { Layout, Input, Tooltip } from "antd"
import Cookies from "js-cookie"
import ChatbotDemo from "./ChatbotDemo"
import Title from "antd/es/typography/Title"
import WelcomeHeader from "./WelcomeHeader"
import { React, useContext, useEffect, useState, useRef } from "react"
import { useStytchUser, useStytchSession } from "@stytch/react"
import axios from "../axios"
import Button from "react-bootstrap/Button"
import DashboardContext from "../DashboardContext"
import { InfoCircleOutlined } from "@ant-design/icons"
import Text from "antd/es/typography/Text"
import { Progress } from "antd"
import GridLoader from "react-spinners/GridLoader"

import Chat from "./Chat"
import { Alert, Space } from "antd"

const { Header, Content, Typography } = Layout

export default function DashboardHome() {
    // The useStytchUser hook will return the existing Stytch User object if one exists
    const { user } = useStytchUser()
    // Get the Stytch Session object if available
    const { session } = useStytchSession()
    const [documentationLink, setDocumentationLink] = useState(null)
    const [email, setEmail] = useState(null)
    const [planType, setPlanType] = useState(null)
    const [sentMessages, setSentMessages] = useState(0)
    const [maxMessages, setMaxMessages] = useState(0)
    const [updatedDocumentationLink, setUpdatedDocumentationLink] = useState(null)
    const context = useContext(DashboardContext)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
    const [namespace, setNamespace] = useState(null)
    const [startedIngesting, setStartedIngesting] = useState(false)
    const [ingestProgress, setIngestProgress] = useState(1)
    const [ingestStatus, setIngestStatus] = useState("active")
    const [error, setError] = useState(null)
    const [showChangeDataSource, setShowChangeDataSource] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768)
        }
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
        if(user) {
            console.log('setting local storage')
            localStorage.setItem('email', user.emails[0].email);
        }
    }, [])

    useEffect(() => {
        if(user) {
            console.log('setting local storage')
            localStorage.setItem('email', user.emails[0].email);
        }
        if (session && session.stytch_session && session.stytch_session.session_token) {
            if (process.env.REACT_APP_ENV == "production") {
                Cookies.remove("stytch_session_spryngtime")
                Cookies.set("stytch_session_spryngtime", session.stytch_session.session_token, {
                    domain: ".spryngtime.com",
                })
            } else {
                Cookies.set("stytch_session_spryngtime", session.stytch_session.session_token, {
                    domain: "localhost",
                })
            }
        } else if (Cookies.get("stytch_session")) {
            Cookies.remove("stytch_session_spryngtime")
            Cookies.set("stytch_session_spryngtime", Cookies.get("stytch_session"), {
                domain: ".spryngtime.com",
            })
        }

        axios.get("/getLoggedInUser", { withCredentials: true }).then((response) => {
            setDocumentationLink(response.data.documentation_link)
            context.documentationLink = response.data.documentation_link

            setPlanType(response.data.planType)
            context.planType = response.data.planType
            setSentMessages(response.data.sentMessages)
            setMaxMessages(response.data.maxMessages)
            context.userId = response.data.userId
            if(context.email == null) {
                context.email = response.data.email
            }
            setEmail(response.data.email)
            localStorage.setItem('documentationLink', context.documentationLink)
            // Also get namespace for user
            axios.get("/getNamespaceForUser", { withCredentials: true }).then((response) => {
                // Check if namespace is null
                if (response.data.name_space) {
                    setNamespace(response.data.name_space)
                }
            })
            setLoading(false)
        })
        if (user && context.email == null && user.emails && user.emails.length > 0 && user.emails[0].email) {
            context.email = user.emails[0].email
        }
    }, [])

    console.log(context)

    const updateDocsLink = () => {
        // Ingest then update docs
        setStartedIngesting(true)
        startProgress()
        // Clear any error and statuses from previous ingest as well as the progress
        setError(null)
        setIngestStatus("active")
        setIngestProgress(1)
        axios
            .post(
                "/ingest",
                { base_url: updatedDocumentationLink, email: localStorage.getItem('email') },
                { withCredentials: true }
            )
            .then((response) => {
                let data = response.data
                if (data.domain) {
                    setNamespace(data.domain)
                    axios
                        .post(
                            "/addDocumentationLink",
                            { documentationLink: updatedDocumentationLink },
                            { withCredentials: true }
                        )
                        .then((response) => {
                            setDocumentationLink(response.data.documentation_link)
                            finishProgress()
                            setIngestStatus("success")
                            localStorage.setItem('documentationLink', context.documentationLink)
                        })
                        .catch((error) => {
                            setIngestStatus("exception")
                            clearProgressTimer()
                            setError(
                                "Sorry, something went wrong while fetching your data source. Please try again later."
                            )
                        })
                } else {
                    // Failed to ingest
                    setIngestStatus("exception")
                    clearProgressTimer()
                    setError(
                        "Sorry, something went wrong while fetching your data source. Please try again later."
                    )
                }
            })
            .catch((error) => {
                setIngestStatus("exception")
                clearProgressTimer()
                setError(
                    "Sorry, something went wrong while fetching your data source. Please try again later."
                )
            })
    }

    // Ref to the interval to clear later
    const progressRef = useRef(null)
    // Ref to the ingest progress to ensure it doesn't go backward
    const ingestProgressRef = useRef(1)

    const startProgress = () => {
        // Start progress bar. Set 2 minutes as the 100% time
        let startTime = new Date().getTime()
        let endTime = startTime + 2 * 60 * 1000 // 2 minutes
        // Randomly increment progress bar every 1-3 seconds by a random amount (1%-5%),
        // ending at 2 minutes
        let interval = setInterval(() => {
            let currentTime = new Date().getTime()
            let elapsedPercent = ((currentTime - startTime) / (endTime - startTime)) * 100
            let randomProgress = Math.random() * 4 + 1 // random increment between 1% to 5%
            // Ensure progress doesn't move backward by taking the maximum value between the
            // current progress and the new computed progress
            let progress = Math.max(
                ingestProgressRef.current,
                Math.min(100, Math.round(elapsedPercent + randomProgress))
            )
            setIngestProgress(progress)
            ingestProgressRef.current = progress
        }, 1000 + Math.random() * 2000) // random interval between 1-3 seconds

        // Add this interval to the ref so we can clear it later
        progressRef.current = interval
    }

    const finishProgress = () => {
        setIngestProgress(100)
        clearProgressTimer()
    }

    const clearProgressTimer = () => {
        if (progressRef.current) {
            clearInterval(progressRef.current)
            // Remove the interval from the ref
            progressRef.current = null
        }
    }

    function setNewSentMessages(newMessage) {
        setSentMessages(newMessage)
    }

    return (
        <Layout>
            <WelcomeHeader planType={planType} hasIngested={documentationLink != null} />
            {
                // Show banner if user has ingested
            }
            {documentationLink && (
                <Space
                    direction="vertical"
                    style={{
                        width: isMobile ? "90vw" : "60vw",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: 10,
                    }}
                >
                    <Alert
                        // message="Free additional message credits"
                        description="Note: If your data source is protected by captcha or bot blocking technology, the data source fetching may take 10-20 minutes!"
                        type="info"
                        showIcon
                        closable
                    />
                </Space>
            )}
            <Content className="documentationContent">
                {loading ? (
                    <GridLoader color="black" loading={true} />
                ) : (
                    <>
                        {documentationLink && (
                            <>
                                <Title level={4} className="noMarginTitle">
                                    Current data source:
                                </Title>
                                <div style={{ display: isMobile ? "block" : "flex" }}>
                                    <Title
                                        level={5}
                                        style={{
                                            paddingLeft: isMobile ? 10 : 0,
                                            paddingRight: isMobile ? 10 : 0,
                                            textAlign: isMobile ? "center" : "left",
                                            paddingTop: isMobile ? 0 : 10,
                                        }}
                                    >
                                        {documentationLink}
                                    </Title>
                                    <Button
                                        style={{
                                            marginLeft: isMobile ? "auto" : "10px",
                                            marginRight: isMobile ? "auto" : 0,
                                            display: isMobile ? "block" : "inline-block",
                                            marginTop: isMobile ? "10px" : 0,
                                        }}
                                        variant="dark"
                                        onClick={() => {
                                            setShowChangeDataSource(true)
                                        }}
                                        size="large"
                                    >
                                        Edit
                                    </Button>
                                </div>
                            </>
                        )}
                        {/* If clicks on edit datasource, or if no datasource exists */}
                        {showChangeDataSource || !documentationLink ? (
                            <>
                                <br />
                                <Tooltip
                                    placement="right"
                                    title="A data source is a website that Spryngtime will ingest and understand the information. Some examples of this is usually public help docs, FAQs, or onboarding documentation. To upgrade to ingest more than 1 data source at a time, email us at help@spryngtime.com"
                                >
                                    <div className="info-container">
                                        <Title level={4} style={{ margin: "0px" }}>
                                            {documentationLink ? "Change data source" : "Add data source"}
                                        </Title>
                                        <InfoCircleOutlined />
                                    </div>
                                </Tooltip>
                                <div style={{ maxWidth: 750 }}>
                                    <Input
                                        onChange={(e) => {
                                            // Use setUpdatedDocumentationLink as a temp variable
                                            setUpdatedDocumentationLink(e.target.value)
                                        }}
                                        onKeyPress={(event) => {
                                            if (event.key === "Enter") {
                                                updateDocsLink()
                                            }
                                        }}
                                        style={{
                                            width: isMobile ? "90vw" : "500px",
                                            maxWidth: 500,
                                            textAlign: "center",
                                            height: "38px",
                                            borderRadius: "5px",
                                            marginTop: "10px",
                                            marginLeft: isMobile ? "5vw" : 0,
                                            marginRight: isMobile ? "5vw" : 0,
                                        }}
                                        placeholder="e.g. https://www.intercom.com/help/en/"
                                    />
                                    <Button
                                        style={{
                                            marginLeft: isMobile ? "auto" : "10px",
                                            marginRight: isMobile ? "auto" : 0,
                                            display: isMobile ? "block" : "inline-block",
                                            marginTop: isMobile ? "10px" : 0,
                                        }}
                                        variant="dark"
                                        onClick={updateDocsLink}
                                        size="large"
                                    >
                                        Fetch data
                                    </Button>
                                </div>
                                <Text
                                    style={{
                                        fontSize: 14,
                                        textAlign: "left",
                                        maxWidth: 600,
                                        marginTop: 5,
                                        padding: isMobile ? 10 : 0,
                                    }}
                                >
                                    This will crawl all the links starting with the URL, then extract the
                                    texts from those links to form the knowledge base of your chat bot.
                                </Text>
                            </>
                        ) : null}
                        {startedIngesting && (
                            <div
                                style={{
                                    width: isMobile ? "90vw" : "600px",
                                }}
                            >
                                <Progress
                                    percent={ingestProgress}
                                    status={ingestStatus}
                                    strokeColor={"#ffb703"}
                                />
                            </div>
                        )}
                        {error && <Text style={{ fontSize: 14, color: "#d90429" }}>{error}</Text>}

                        <p></p>
                        {documentationLink && (!startedIngesting || ingestProgress == 100) ? (
                            <>
                                <Chat
                                    docName={namespace}
                                    setNewSentMessages={setNewSentMessages}
                                    email={email}
                                    isMobile={isMobile}
                                />

                                {/* <Tooltip
                                    placement="right"
                                    title="How many messages have been sent to you during testing or to your customers"
                                >
                                    <div className="info-container">
                                        <Text>Messages sent: {sentMessages}</Text>
                                        <InfoCircleOutlined />
                                    </div>
                                </Tooltip> */}
                                <Tooltip
                                    placement="right"
                                    title="How many messages you have left on your current plan. Upgrade your plan to get more messages!"
                                >
                                    <div className="info-container" style={{ fontSize: 16, marginTop: 5 }}>
                                        <Text>Messages left: {maxMessages - sentMessages}</Text>
                                        <InfoCircleOutlined />
                                    </div>
                                </Tooltip>
                            </>
                        ) : null}
                    </>
                )}
            </Content>

            <Content className="content"></Content>
        </Layout>
    )
}
