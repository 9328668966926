import { Layout, Input, Button } from "antd"
import ChatbotDemo from "./ChatbotDemo"
import Title from "antd/es/typography/Title"
import WelcomeHeader from "./WelcomeHeader"
import { React, useContext, useEffect, useState } from "react"
import { useStytchUser, useStytchSession } from "@stytch/react"
import axios from "../axios"
import DashboardContext from "../DashboardContext"
import Text from "antd/es/typography/Text"
import { useNavigate } from "react-router-dom"
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google"

const { Header, Content, Typography } = Layout

export default function InstallChatAppPage() {
    const nav = useNavigate()
    // The useStytchUser hook will return the existing Stytch User object if one exists
    const { user } = useStytchUser()
    // Get the Stytch Session object if available

    const [planType, setPlanType] = useState(null)

    const context = useContext(DashboardContext)

    useEffect(() => {
        if (user == null) {
            // Not logged in
            nav("/")
            return
        }
        if(context.email == null) {
            console.log('getting local storage')
            context.email = localStorage.getItem('email');
            context.documentationLink = localStorage.getItem('documentationLink');
        }

        console.log(context.email)
        console.log(context.documentationLink)
    }, [])

    const saveGoogleOauth = (coderesponse) => {
        console.log("codeResponse ", coderesponse)
        axios
            .post(
                "/saveGoogleOauthAuthorizationToken",
                { authorizationToken: coderesponse.code },
                { withCredentials: true }
            )
            .then((response) => {
                console.log("successful saveGoogleOauth")
            })
    }

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => saveGoogleOauth(codeResponse),
        scope: "https://mail.google.com/ https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
        redirectUri: "https://dashboard.spryngtime.com/install",
        flow: "auth-code",
    })

    const installDiscord = () => {
        window.open(discordURL, "_blank")
    }

    const installIntercom = () => {
        window.open(intercomURL, "_blank")
    }

    const showEmailAndZendeskToDo = () => {
        window.alert(
            "This is a premium feature! Email us at help@spryngtime.com and we can help you get set up!"
        )
    }
    let slackURL =
        "https://slack.com/oauth/v2/authorize?client_id=2888395477238.5258768446131&scope=app_mentions:read,channels:history,channels:join,channels:read,chat:write,commands,groups:history,groups:read,groups:write&user_scope=&state=" +
        context.email
    let discordURL =
        "https://discord.com/oauth2/authorize?client_id=1107712275592261763&permissions=68608&redirect_uri=https%3A%2F%2Fsupport-bot-backend.onrender.com%2FdiscordCustomerSupportInstall&response_type=code&scope=bot&state=" +
        context.email
    let intercomURL =
        "https://app.intercom.com/oauth?client_id=90af2a72-66f6-4a4b-bb9f-5c39a129a732&state=" + context.email

    return (
        <Layout>
            <WelcomeHeader planType={planType} hasIngested={context.documentationLink != null} />
            <Content className="documentationContent">
                <Title level={4}>Install Spryngtime on your favorite support channels!</Title>
                <p></p>

                <a href={slackURL}>
                    <img
                        alt="Add to Slack"
                        height="50"
                        width="174"
                        src="https://platform.slack-edge.com/img/add_to_slack.png"
                        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                    />
                </a>
                <Button
                    style={{ height: "50px", width: "174px", marginTop: "20px" }}
                    onClick={installIntercom}
                    size="lg"
                >
                    Add to Intercom
                </Button>
                <p></p>
                <Button
                    style={{ height: "50px", width: "174px", marginTop: "10px" }}
                    onClick={installDiscord}
                    size="lg"
                >
                    Add to Discord
                </Button>
                <p></p>
                <Button
                    style={{ height: "50px", width: "174px", marginTop: "10px" }}
                    variant="success"
                    onClick={() => login()}
                    size="lg"
                >
                    Connect with Gmail 📫
                </Button>
                <p></p>
                <Button
                    style={{ height: "50px", width: "174px", marginTop: "10px" }}
                    variant="info"
                    onClick={showEmailAndZendeskToDo}
                    size="lg"
                >
                    Add to Zendesk
                </Button>
                {/*<GoogleLogin*/}
                {/*    onSuccess={credentialResponse => {*/}
                {/*        console.log(credentialResponse);*/}
                {/*    }}*/}
                {/*    onError={() => {*/}
                {/*        console.log('Login Failed');*/}
                {/*    }}*/}
                {/*/>*/}
                {/*<Button onClick={() => login()}>*/}
                {/*    Connect with Google 🚀{' '}*/}
                {/*</Button>*/}
            </Content>
        </Layout>
    )
}
