import { React, useContext, useEffect, useState } from "react"
import DashboardContext from "../DashboardContext"
import { Typography, Layout, Button, Drawer } from "antd"
import { MenuOutlined } from "@ant-design/icons"
import axios from "../axios"
import { useNavigate } from "react-router-dom"
import { useStytch, useStytchUser } from "@stytch/react"

const { Title } = Typography
const { Header } = Layout

export default function WelcomeHeader({ planType, hasIngested }) {
    const context = useContext(DashboardContext)
    const nav = useNavigate()
    const stytchClient = useStytch()
    // The useStytchUser hook will return the existing Stytch User object if one exists
    const { user } = useStytchUser()
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
    const [visible, setVisible] = useState(false)
    const [hasIngestedLocal, setHasIngestedLocal] = useState(null)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768)
        }
        window.addEventListener("resize", handleResize)
        if (context.documentationLink != null) {
            setHasIngestedLocal(true)
        }
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const handleSignOut = () => {
        stytchClient.session.revoke()
        nav("/")
    }

    const goHome = () => {
        nav("/")
    }

    const goInstall = () => {
        nav("/install")
    }

    const goAutoDocument = () => {
        nav("/auto-doc")
    }

    const handlePayment = () => {
        console.log("user id is " + context.userId)
        console.log("email is " + context.email)
        window.open(
            "https://buy.stripe.com/aEU8yb7au56C0BGeV4?prefilled_email=" +
                encodeURIComponent(context.email) +
                "&client_reference_id=" +
                context.userId,
            "_blank"
        )
    }

    return (
        <>
            {
                <Header className="welcome_header">
                    <div className="modal_icon">
                        <a href="/" className="modal_icon">
                            <img className="modal_icon_img" src="./logo.png" />
                        </a>
                        <a href="/" className="welcome_text">
                            <Title level={3} className="welcome_text" onClick={goHome}>
                                Spryngtime
                            </Title>
                        </a>
                    </div>

                    {!isMobile ? (
                        <>
                            <Title level={3} className="welcome_text"></Title>

                            <div>
                                {((planType && planType.includes("trial")) ||
                                    (context.planType && context.planType.includes("trial"))) && (
                                    <Button
                                        variant="primary"
                                        onClick={handlePayment}
                                        size="large"
                                        style={{ marginRight: "10px" }}
                                    >
                                        ✨Upgrade✨
                                    </Button>
                                )}

                                {/*Not a scalable way if we have multiple pages, hasIngested doesn't work on documentation page for example*/}
                                {(hasIngested || hasIngestedLocal) && (
                                    <Button
                                        variant="dark"
                                        onClick={goInstall}
                                        style={{ marginRight: "10px" }}
                                        size="large"
                                    >
                                        Install
                                    </Button>
                                )}
                                <Button
                                    variant="dark"
                                    onClick={goAutoDocument}
                                    style={{ marginRight: "10px" }}
                                    size="large"
                                >
                                    Auto-document
                                </Button>

                                <Button
                                    variant="dark"
                                    onClick={handleSignOut}
                                    style={{ marginRight: "20px" }}
                                    size="large"
                                >
                                    Sign Out
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <Button className="barsMenu" onClick={() => setVisible(true)}>
                                <MenuOutlined />
                            </Button>
                            <Drawer
                                title="Menu"
                                placement="right"
                                closable={false}
                                onClose={() => setVisible(false)}
                                visible={visible}
                            >
                                <div className="drawerMenu">
                                    {((planType && planType.includes("trial")) ||
                                        (context.planType && context.planType.includes("trial"))) && (
                                        <Button
                                            variant="primary"
                                            onClick={handlePayment}
                                            size="large"
                                            style={{ marginTop: "20px" }}
                                        >
                                            ✨Upgrade✨
                                        </Button>
                                    )}

                                    <Button
                                        variant="dark"
                                        onClick={goInstall}
                                        style={{ marginTop: "20px" }}
                                        size="large"
                                    >
                                        Install
                                    </Button>

                                    <Button
                                        variant="dark"
                                        onClick={goAutoDocument}
                                        style={{ marginTop: "20px" }}
                                        size="large"
                                    >
                                        Auto-document
                                    </Button>

                                    <Button
                                        variant="dark"
                                        onClick={handleSignOut}
                                        style={{ marginTop: "20px" }}
                                        size="large"
                                    >
                                        Sign Out
                                    </Button>
                                </div>
                            </Drawer>
                        </>
                    )}
                </Header>
            }
        </>
    )
}
