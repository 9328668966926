import { useState, useEffect, React, useContext } from "react"
import { Typography } from "antd"
import { useLocation } from "react-router-dom"
import axios from "../axios"
import { useNavigate } from "react-router-dom"
import { ArrowRight } from "react-bootstrap-icons"
import Button from "react-bootstrap/Button"
import ClipLoader from "react-spinners/ClipLoader"
import { useStytchUser, useStytchSession } from "@stytch/react"
import { Form, Input } from "antd"
import Login from "./Login"
const { Text, Title } = Typography

export default function LoginPage() {
    const queryParams = new URLSearchParams(useLocation().search)
    const [loginFailed, setLoginFailed] = useState(false)

    // The useStytchUser hook will return the existing Stytch User object if one exists
    const { user } = useStytchUser()
    // Get the Stytch Session object if available
    const { session } = useStytchSession()
    const token = queryParams.get("token")
    const [sentMagicLink, setSentMagicLink] = useState(false)
    const [authenticatedEmail, setAuthenticatedEmail] = useState("")
    const [inVerifyMode, setInVerifyMode] = useState(false)
    const [loading, setLoading] = useState(true)

    const nav = useNavigate()

    useEffect(() => {
        setLoginFailed(true)
        setLoading(false)
        console.log("user")
        console.log(user)
        if (user) {
            setLoginFailed(false)
            nav("/test")
        }
    }, [])

    const handleSubmit = async (values) => {
        // make sure email is lowercased
        const userEmail = values["email"].toLowerCase()
        axios.get(`/createMagicLink?email=${userEmail}`).then((response) => {
            if (response.status == 200 && response.data.response.status_code == 200) {
                setLoginFailed(false) // in case this was previously true and this is a retry
                setSentMagicLink(true)
            }
        })
    }

    return (
        <Form onFinish={handleSubmit} className="login_page" style={{ paddingTop: "50px" }}>
            <img src="./logo.png" style={{ maxWidth: "120px" }} />

            {loading && (
                <Title>
                    <ClipLoader color="black" loading={true} />{" "}
                </Title>
            )}

            {!loading && (
                <>
                    {!sentMagicLink && authenticatedEmail == "" && !inVerifyMode && (
                        <>
                            <Title level={3}>Login with your email to start creating your chatbots!</Title>

                            <Login />
                        </>
                    )}

                    {sentMagicLink && authenticatedEmail == "" && !inVerifyMode && (
                        <Text>We sent you a login link! Check your email. </Text>
                    )}
                    {inVerifyMode && !loginFailed && <Text>One second as we log you in..</Text>}
                </>
            )}
        </Form>
    )
}
