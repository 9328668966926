import { useState, useEffect, React, useContext } from "react"
import { Row, Col, Layout, Typography } from "antd"
import axios from "../axios"
import NFTCard from "./NFTCard"
import WelcomeHeader from "./WelcomeHeader"
import DashboardContext from "../DashboardContext"
import { useNavigate } from "react-router-dom"
import Grid from "@mui/material/Grid"
import ClipLoader from "react-spinners/ClipLoader"
import { useStytchUser } from "@stytch/react";

const { Title } = Typography
const { Header, Content } = Layout

export default function ChatbotDemo({passedInDocumentationLink}) {
    const [nfts, setNfts] = useState([])
    const [authdEmail, setAuthdEmail] = useState(null)
    const [documentationLink, setDocumentationLink] = useState(null)

    const nav = useNavigate()
    // The useStytchUser hook will return the existing Stytch User object if one exists
    const { user } = useStytchUser();

    useEffect(() => {
        console.log("passedInDocumentationLink " + passedInDocumentationLink)
    })

    let chatURL = "https://ai-documentation-chat.onrender.com/"
    if(user && passedInDocumentationLink) {
        chatURL = "https://ai-documentation-chat.onrender.com/?emailParam=" + user.emails[0].email+
            "&baseDocURL=" + passedInDocumentationLink
    }

    return (
        <div className="chat_demo">
            {user != null && (
                <iframe className="chat_demo_iframe" src={chatURL} height="400px" width="100vw"/>
            )}
        </div>
    )
}
